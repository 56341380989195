.swiper {
  &.onboarding-swiper {
    .swiper-wrapper {
      padding-bottom: 0px;
    }

    .swiper-pagination {
      &.swiper-pagination-bullets {
        &.onboarding-pagination {
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 70px;

          .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background: #ffff;
            opacity: 0.6;

            &.swiper-pagination-bullet-active {
              width: 14px;
              height: 14px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
