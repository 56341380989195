body {
  // needed for barcode scanner
  &.barcode-scanner-active {
    --background: transparent;
    --ion-background-color: transparent;
  }
}

.onboarding-hint {
  --background: #3797DA;
  --offset-y: -5px;
  // display popover behind the modals
  z-index: 100 !important;
}
