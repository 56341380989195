ion-radio {
  &.item-by-language-radio {
    &::part(container) {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #979797;
    }

    &::part(mark) {
      background: none;
      transition: none;
      transform: none;
      border-radius: 0;
    }

    &.radio-checked {
      &::part(container) {
        background: #A4D5CD;
        border-color: transparent;
      }

      &::part(mark) {
        display: none;
      }
    }
  }
}

ion-item {
  &.item-by-language {
    &::part(native) {
      background: #ffffff;
    }
  }
}