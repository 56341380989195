ion-segment-button {
  --indicator-height: 0;
  --background-checked: var(--ion-color-primary);
  --border-radius: 15px 15px 0 0;

  --color: #535e69;
  --color-checked: #ffffff;

  --padding-start: 5px;
  --padding-end: 5px;

  text-transform: capitalize;
}
