ion-modal {
  &.quiz-modal {
    --height: auto;
    --max-width: 400px;
    --border-radius: 30px;
    --box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
    padding: 10px;
    right: 30px;
    left: 30px;

    &::part(content) {
      position: relative;
      top: -20%;
    }
  }
}
