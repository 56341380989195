// Import Swiper styles
@import "swiper/scss"; // Core swipter styles
@import "swiper/scss/navigation"; // Navigation module
@import "swiper/scss/pagination"; // Pagination module
@import "swiper/scss/virtual"; // Virtual module

:root {
  --swiper-theme-color: var(--ion-color-primary);
  --swiper-pagination-bullet-inactive-color: var(--ion-color-primary);
  --swiper-pagination-bullet-inactive-opacity: 0.8;
}
.swiper {
  width: 100%;

  .swiper-wrapper {
    padding-bottom: 28px;
  }
}
