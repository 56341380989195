ion-modal {
  &#app-version-features-modal {
    --width: 80%;
    --min-width: 300px;
    --max-width: 600px;

    --height: auto;
    --min-height: 300px;
    --max-height: 80vh;

    --border-radius: 6px;
    padding: 0 50px;

    .content {
      padding: 0;
      overflow-y: auto;
      max-height: 80vh;
    }
  }
}
