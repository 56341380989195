ion-range {
  &::part(pin) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background: none;
    color: #000;
    font-size: 1rem;
    font-weight: 700;
    transform: scale(1) translateX(-50%);
    top: -20px;
    left: 50%;

    &::before {
      content: none;
    }
  }

  &::part(bar) {
    height: 3px;
    background: #DDDEDF;
  }

  &::part(bar-active) {
    height: 3px;
  }
}