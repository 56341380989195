ion-radio {
  &.item-premium-access-radio {
    margin-right: 24px;

    &::part(container) {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #D5D6DC;
    }

    &::part(mark) {
      background: none;
      transition: none;
      transform: none;
      border-radius: 0;
    }

    &.radio-checked {
      &::part(container) {
        background: #D74827;
        border-color: transparent;
      }

      &::part(mark) {
        display: none;
      }
    }
  }
}
